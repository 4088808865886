import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '292981',
        secondary: '#fff',
        accent: '#ccc',
        error: colors.red.accent3,
      },
      dark: {
        accent: colors.blue.base,
      },
    },
  },
});
