import Vue from 'vue'
import VueCookies from 'vue-cookies'
import axios from 'axios'

Vue.use(VueCookies)

/*
** Criando a instância do axios
*/
const api = axios.create({
    // baseURL: "http://localhost:8000"
    baseURL: "https://ehomeapi.encode.dev.br"
})

/*
** Definindo um token de segurança para requisições
** feitas por usuários logados para endpoints que esperam um token
*/
api.interceptors.request.use(async config => {
    api.defaults.headers.authorization = `Bearer ${Vue.$cookies.get('token')}`
    return config
})

export default api