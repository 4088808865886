import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/Layout.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login')
  },
  {
    path: '/',
    redirect: '/dashboard',
    component: Layout,
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        meta: {
          title: 'Dashboard'
        },
        component: () => import('@/views/Dashboard')
      },
      {
        path: '/users',
        name: 'users',
        meta: {
          title: 'Usuários'
        },
        component: () => import('@/views/Users')
      },
      {
        path: '/clients',
        name: 'clients',
        meta: {
          title: 'Clientes'
        },
        component: () => import('@/views/Clients')
      },
      {
        path: '/owners',
        name: 'owners',
        meta: {
          title: 'Proprietários'
        },
        component: () => import('@/views/Owners')
      },
      {
        path: '/properties',
        name: 'properties',
        meta: {
          title: 'Imóveis'
        },
        component: () => import('@/views/Properties')
      },
      {
        path: '/notifications',
        name: 'notifications',
        meta: {
          title: 'Notificações'
        },
        component: () => import('@/views/Notifications')
      },
      {
        path: '/notifications/:id',
        name: 'view-notification',
        meta: {
          title: 'Notificações'
        },
        component: () => import('@/views/ViewNotification')
      },
      {
        path: 'admin/clients',
        name: 'admin-clients',
        meta: {
          title: 'Clientes'
        },
        component: () => import('@/views/admin/Clients')
      },
      {
        path: 'admin/users',
        name: 'admin-users',
        meta: {
          title: 'Usuários'
        },
        component: () => import('@/views/Users')
      },
      
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched[0].name === 'admin' && Vue.$cookies.get('system_client_id') == 'null') {
    next({ name: to.matched[1].name })
  }
  
  if (to.name !== 'login' && !Vue.$cookies.isKey('user_id')) {
    next({ name: 'login' })
  } else {
    next()
  }
})

export default router
