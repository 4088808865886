import Vue from 'vue'
import VueCookies from 'vue-cookies'
import VueSession from 'vue-session'
import VueTheMask from 'vue-the-mask'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false
Vue.prototype.$api = api
Vue.use(VueCookies)
Vue.use(VueSession)
Vue.use(VueTheMask)

import api from './provider/api'

export const eventbus = new Vue({
  methods: {
    typingSearch(value) {
      this.$emit('searching', value)
    }
  }
})

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
