<template>
  <v-app 
    :style="{
      backgroundColor: $vuetify.theme.dark 
      ? $vuetify.theme.themes.dark.background 
      : $vuetify.theme.themes.light.background
    }"
  >
    <Drawer @toggleDrawer="e => drawer = e" :drawer="drawer"/>
    <AppBar :drawer="$vuetify.breakpoint.mobile ? drawer : !drawer" @toggleDrawer="drawer = !drawer"/>
    <v-main>
      <v-container fluid>
        <router-view/>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  components: {
    Drawer: () => import('./Drawer'),
    AppBar: () => import('./AppBar')
  },
  data: () => ({
    drawer: false
  })
}
</script>